div.titleContainer {
  padding: 24px 12px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: flex-end;

  h6 {
    margin-right: 24px;
  }

  @media screen and (max-width: 800px) {
    padding: 4px 8px;
  }
}

div.filterContainer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 3px;

  p {
    margin-right: 24px;
  }
}

.italicTypography {
  font-style: italic;
}

.clickableTypography {
  cursor: pointer !important;
}
