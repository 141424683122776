.Root {
  position: relative;
  height: 100%;
  width: 100%;
}

.Map {
  position: relative;
  height: auto;
  width: 100%;
  flex: 1 1 auto;
}

.Svg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
  pointer-events: none;
}

.Handle {
  z-index: 5000;
  position: absolute;
  background-color: white;
  border-radius: 1px;
  transform: translate(-50%, -50%);

  & > button {
    pointer-events: all;
    cursor: ew-resize;
    width: 100%;
    height: 100%;
  }
}
