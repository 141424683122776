.AppBar {
  border-bottom: 1px solid #eeeeee;
}

.TextFieldBox {
  display: flex;
  flex-direction: row;
}

button.Button {
  width: 100%;
  height: 40px;
  margin-top: 8px;
  margin-bottom: 4px;
  white-space: nowrap;
}

.ButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Toolbar {
  justify-content: space-between;
  min-height: unset !important;
}
