td.bodyCell1 {
  padding-left: 8px;
  padding-bottom: 0;
  padding-top: 0;
  width: 15%;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

td.bodyCell {
  padding-left: 8px;
  padding-bottom: 0;
  padding-top: 0;
  width: 15%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

button.action {
  padding: 4px;
  margin-right: 6px;
}
